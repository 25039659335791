import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import { Helmet } from 'react-helmet'

import StakewayNavigation from '../components/stakeway-navigation'
import StakewayHighlight from '../components/stakeway-highlight'
import Tag from '../components/tag'
import ChevronAssetChild from '../components/chevron-asset-child'
import StakewayCard from '../components/stakeway-card'
import StakewayCardReverse from '../components/stakeway-card-reverse'
import StakewayProduct from '../components/stakeway-product'
import StakewayFooter from '../components/stakeway-footer'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container10">
      <Helmet>
        <title>Stakeway</title>
        <meta property="og:title" content="Stakeway" />
      </Helmet>
      <StakewayNavigation></StakewayNavigation>
      <div className="home-highlight1">
        <StakewayHighlight
          text="BTC Staking is live"
          buttonLabel="STAKE NOW"
          rootClassName="stakeway-highlightroot-class-name"
        ></StakewayHighlight>
      </div>
      <div className="home-hero">
        <div className="home-left-part">
          <Tag
            text="Institution grade staking"
            rootClassName="tagroot-class-name15"
          ></Tag>
          <span className="home-text100">
            <span>Secure.</span>
            <br></br>
            <span>Tailored.</span>
            <br></br>
            <span>Unslashable</span>
          </span>
          <a
            href="/book-a-call"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link1"
          >
            <div className="home-button">
              <span className="home-text106">STAKE NOW</span>
              <img
                alt="trendingup1067"
                src="/external/trendingup1067-5sa8.svg"
                className="home-trendingup"
              />
            </div>
          </a>
        </div>
        <div className="home-right-part">
          <div id="home-right-part-child1" className="home-frame1171275257">
            <div className="home-frame1171275256">
              <span className="home-text107">
                Enter amount to calculate rewards
              </span>
              <div className="home-frame482019">
                <span className="home-text108">
                  <span>USD</span>
                </span>
              </div>
            </div>
            <div className="home-frame482204">
              <div className="home-frame481578">
                <div className="home-frame11712752541">
                  <div className="home-logoethereum">
                    <img
                      id="home-interactive-element-image"
                      alt="image"
                      src="/external/logos/logo-ethereum-200h.png"
                      className="home-image1"
                    />
                  </div>
                  <div className="home-frame481505">
                    <div className="home-frame4815121">
                      <span
                        id="home-interactive-element-asset-name"
                        className="home-text110"
                      >
                        Ethereum
                      </span>
                    </div>
                    <span
                      id="home-interactive-element-asset-percentage"
                      className="home-text111"
                    >
                      3.43%
                    </span>
                    <span id="home-dropdown-asset-price" className="ratio">
                      123
                    </span>
                  </div>
                </div>
                <div className="home-frame1171275255">
                  <input
                    type="text"
                    id="asset-home-input-amount"
                    placeholder="100"
                    className="home-input input"
                  />
                  <div id="asset-home-drop-down" className="home-container11">
                    <img
                      alt="SVG24iconchevron1110"
                      src="/external/svg24iconchevron1110-uria.svg"
                      className="home-svg24iconchevron"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div id="asset-dropdown-list" className="home-container12">
              <ChevronAssetChild
                text={
                  <Fragment>
                    <span className="home-text113">BTC</span>
                  </Fragment>
                }
                text1={
                  <Fragment>
                    <span className="home-text114">2.87%</span>
                  </Fragment>
                }
                imageSrc="/external/btc-logo-200h.png"
                rootClassName="chevron-asset-childroot-class-name"
              ></ChevronAssetChild>
              <ChevronAssetChild
                text={
                  <Fragment>
                    <span className="home-text115">ETH</span>
                  </Fragment>
                }
                text1={
                  <Fragment>
                    <span className="home-text116">3.43%</span>
                  </Fragment>
                }
                imageSrc="/external/logo-ethereum1-200h.png"
              ></ChevronAssetChild>
              <ChevronAssetChild
                text={
                  <Fragment>
                    <span className="home-text117">POL</span>
                  </Fragment>
                }
                text1={
                  <Fragment>
                    <span className="home-text118">5.6%</span>
                  </Fragment>
                }
                imageSrc="/external/logopolygon1318-449p.svg"
              ></ChevronAssetChild>
              <ChevronAssetChild
                text={
                  <Fragment>
                    <span className="home-text119">GNO</span>
                  </Fragment>
                }
                text1={
                  <Fragment>
                    <span className="home-text120">12%</span>
                  </Fragment>
                }
                imageSrc="/external/Stakeway/assets/gnosis-400h.png"
              ></ChevronAssetChild>
              <div className="home-container13">
                <a
                  href="/asset-page1"
                  id="home-asset-view-all-button"
                  target="_blank"
                  rel="noreferrer noopener"
                  className="home-link2 button"
                >
                  View all
                </a>
              </div>
            </div>
          </div>
          <img
            alt="Line61110"
            src="/external/line61110-6csu.svg"
            className="home-line6"
          />
          <div className="home-frame1171275258">
            <div className="home-frame4815122">
              <span className="home-text121">Yield per annum</span>
            </div>
            <span id="home-yield-per-annum" className="home-text122">
              $2546.98
            </span>
          </div>
        </div>
      </div>
      <div id="rotating-container" className="home-frame1171275238">
        <div id="rotating-content" className="home-container14">
          <img
            alt="Frame1384"
            src="/external/frame1384-j36d.svg"
            className="home-frame10"
          />
          <img
            alt="flare11066"
            src="/external/flare11066-sh2h.svg"
            className="home-flare11"
          />
          <img
            alt="Frame11712751261066"
            src="/external/frame11712751261066-0kom.svg"
            className="home-frame11712751261"
          />
          <img
            alt="Frame1067"
            src="/external/frame1067-vbt.svg"
            className="home-frame11"
          />
          <img
            alt="availlogo9c818c5a11105"
            src="/external/availlogo9c818c5a11105-793-200h.png"
            className="home-availlogo9c818c5a11"
          />
          <img
            alt="Frame1105"
            src="/external/frame1105-bbqm.svg"
            className="home-frame13"
          />
          <img
            alt="Frame1105"
            src="/external/frame1105-1r9.svg"
            className="home-frame14"
          />
          <img
            alt="Frame1105"
            src="/frame_stellar.svg"
            className="home-frame15"
          />
          <img
            alt="Frame1384"
            src="/external/frame1384-j36d.svg"
            className="home-frame16"
          />
          <img
            alt="flare11066"
            src="/external/flare11066-sh2h.svg"
            className="home-flare12"
          />
          <img
            alt="Frame11712751261066"
            src="/external/frame11712751261066-0kom.svg"
            className="home-frame11712751262"
          />
          <img
            alt="Frame1067"
            src="/external/frame1067-vbt.svg"
            className="home-frame17"
          />
          <img
            alt="availlogo9c818c5a11105"
            src="/external/availlogo9c818c5a11105-793-200h.png"
            className="home-availlogo9c818c5a12"
          />
          <img
            alt="Frame1105"
            src="/external/frame1105-bbqm.svg"
            className="home-frame18"
          />
          <img
            alt="Frame1105"
            src="/external/frame1105-1r9.svg"
            className="home-frame19"
          />
          <img
            alt="Frame1105"
            src="/frame_stellar.svg"
            className="home-frame20"
          />
        </div>
      </div>
      <div className="home-container15">
        <div className="home-container16">
          <StakewayCard rootClassName="stakeway-cardroot-class-name1"></StakewayCard>
          <StakewayCard
            text="We're among top 10 Lido validators"
            image="/external/Stakeway/assets/lido-400h.png"
            label="TOP 10"
            rootClassName="stakeway-cardroot-class-name"
          ></StakewayCard>
        </div>
        <div className="home-us-ps">
          <div className="home-content">
            <div className="home-frame482119">
              <div className="home-frame1171275281">
                <img
                  alt="banking0111384"
                  src="/external/banking0111384-9w5l.svg"
                  className="home-banking011"
                />
              </div>
              <div className="home-frame482177">
                <span className="home-text123">
                  <span>Non-custodial</span>
                  <br></br>
                  <span>staking</span>
                </span>
              </div>
            </div>
            <div className="home-frame482120">
              <div className="home-frame1171275282">
                <img
                  alt="banking0211384"
                  src="/external/banking0211384-h38s.svg"
                  className="home-banking021"
                />
              </div>
              <div className="home-frame482131">
                <span className="home-text127">
                  <span>Compliance and</span>
                  <br></br>
                  <span>risk mitigation</span>
                </span>
              </div>
            </div>
            <div className="home-frame482121">
              <div className="home-frame1171275283">
                <img
                  alt="banking2311384"
                  src="/external/banking2311384-loit.svg"
                  className="home-banking231"
                />
              </div>
              <div className="home-frame482176">
                <span className="home-text131">
                  <span>Immaculate</span>
                  <br></br>
                  <span>performance</span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="home-container17">
          <StakewayCard
            text="Effective uptime"
            image="/external/Stakeway/assets/server-400h.png"
            label="99.9%"
            rootClassName="stakeway-cardroot-class-name2"
          ></StakewayCard>
          <StakewayCard
            text="With APR up to 12%"
            image="/external/Stakeway/assets/gnosis-400h.png"
            label="12%"
            rootClassName="stakeway-cardroot-class-name3"
          ></StakewayCard>
        </div>
      </div>
      <div className="home-frame1171275274">
        <div className="home-frame1171275273">
          <span className="home-text135">Custom built infrastructure</span>
          <a
            href="/book-a-call"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link3"
          >
            <div className="home-frame1171275243">
              <span className="home-text136">ORDER A PROJECT</span>
              <img
                alt="bullseye1067"
                src="/external/bullseye1067-stx9.svg"
                className="home-bullseye"
              />
            </div>
          </a>
        </div>
        <span className="home-text137">
          We serve most demanding clients with custom infrastructure solutions
        </span>
      </div>
      <div className="home-highlight2">
        <div className="home-container18">
          <div className="home-container19">
            <div className="home-title">
              <img
                alt="image"
                src="/external/Stakeway/assets/pwc-200h.png"
                className="home-image2"
              />
              <span className="home-text138">PwC audited since 2021</span>
            </div>
          </div>
        </div>
      </div>
      <div className="home-container20">
        <StakewayCardReverse
          text="Have a setup in Puerto-Rico for tax advantages or Switzerland for crypto-friendliness"
          image="/external/Stakeway/assets/planet-400h.png"
          label="Any place"
        ></StakewayCardReverse>
        <div className="home-container21">
          <div className="home-frame11712752542">
            <span className="home-text139">35 add-ons</span>
            <span className="home-text140">
              Our Staking infrastructure offers over 35 customisations from our
              partners
            </span>
            <img
              alt="image"
              src="/external/Stakeway/assets/addons-700w.png"
              className="home-image3"
            />
          </div>
        </div>
      </div>
      <div className="home-container22">
        <div className="home-frame1171275277">
          <div className="home-frame1171275275">
            <span className="home-text141">
              <span>Staking market</span>
              <br></br>
              <span>outlook</span>
            </span>
            <span className="home-text145">
              This is what has been happening in ‘23, and what’s coming ahead
            </span>
            <Link to="/blog-staking-market" className="home-navlink1">
              <div className="home-frame1171275247">
                <span className="home-text146">Learn more</span>
                <img
                  alt="chevronright1067"
                  src="/external/chevronright1067-742.svg"
                  className="home-chevronright"
                />
              </div>
            </Link>
          </div>
          <div className="home-frame1171275276">
            <Link to="/blog-staking-rewards" className="home-navlink2">
              <div className="home-frame1171275248">
                <span className="home-text147">
                  Maximizing Staking Rewards: Strategies for Optimal Node
                  Performance
                </span>
              </div>
            </Link>
            <Link to="/blog-staking-security" className="home-navlink3">
              <div className="home-frame1171275249">
                <span className="home-text148">
                  Staking Security: Best Practices for Protecting Your Stake and
                  Node
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
      <div className="home-container23">
        <StakewayProduct link="/custodians"></StakewayProduct>
        <StakewayProduct
          link="/pos-networks"
          text="Institutional crypto staking: Pooled fund delegation for optimized yields on your assets"
          label="Institution"
        ></StakewayProduct>
        <StakewayProduct
          link="/pos-networks"
          text="PoS network staking: Validator node operation for blockchain security and rewards"
          label="PoS network"
        ></StakewayProduct>
      </div>
      <div className="home-container24">
        <div className="home-frame1171275278">
          <span className="home-text149">Free Staking advice</span>
          <span className="home-text150">
            Connect to one of our Staking Experts to get free guidance on
            Staking
          </span>
          <div className="home-frame4816321">
            <div className="home-frame481630">
              <img
                alt="check1110"
                src="/external/check1110-wgra.svg"
                className="home-check1"
              />
              <span className="home-text151">No obligations</span>
            </div>
            <div className="home-frame481631">
              <img
                alt="check1110"
                src="/external/check1110-tf3h.svg"
                className="home-check2"
              />
              <span className="home-text152">Time that suits you</span>
            </div>
            <div className="home-frame4816322">
              <img
                alt="check1110"
                src="/external/check1110-m8j9.svg"
                className="home-check3"
              />
              <span className="home-text153">Casual conversation</span>
            </div>
          </div>
        </div>
        <iframe
          src="https://calendly.com/bogdan-jovicevic-gateway/30min"
          className="home-iframe"
        ></iframe>
      </div>
      <div id="auto-scrolling-container" className="home-frame1171275096">
        <div id="auto-scrolling-container-content" className="home-container25">
          <div className="home-frame11712750641">
            <div className="home-frame410">
              <div className="home-frame810">
                <div className="home-frame11712751151">
                  <div className="home-ethereumlogo201411">
                    <div className="home-group3">
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-zl48g.svg"
                        className="home-vector10"
                      />
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-w7fn.svg"
                        className="home-vector11"
                      />
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-vf95.svg"
                        className="home-vector12"
                      />
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-bvw4.svg"
                        className="home-vector13"
                      />
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-kot5.svg"
                        className="home-vector14"
                      />
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-3w46.svg"
                        className="home-vector15"
                      />
                    </div>
                  </div>
                </div>
                <div className="home-group110">
                  <span className="home-text154">ETH</span>
                </div>
                <div className="home-group210">
                  <span className="home-text155">Ethereum</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-7rq.svg"
                className="home-line510"
              />
              <span id="apr-ethereum" className="home-text156">
                3.09% APR
              </span>
            </div>
          </div>
          <div className="home-frame11712750631">
            <div className="home-frame411">
              <div className="home-frame811">
                <img
                  alt="Fill11110"
                  src="/external/btc-logo-200h.png"
                  className="home-fill11"
                />
                <div className="home-group111">
                  <span className="home-text157">BTC</span>
                </div>
                <div className="home-group211">
                  <span className="home-text158">Bitcoin</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-yzp.svg"
                className="home-line511"
              />
              <span id="apr-bitcoin" className="home-text159">
                4.00% APR
              </span>
            </div>
          </div>
          <div className="home-frame121">
            <div className="home-frame412">
              <div className="home-frame812">
                <img
                  alt="Polygon1110"
                  src="/external/polygon1110-b058.svg"
                  className="home-polygon1"
                />
                <div className="home-group112">
                  <span className="home-text160">MATIC</span>
                </div>
                <div className="home-group212">
                  <span className="home-text161">Polygon</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-93o.svg"
                className="home-line512"
              />
              <span id="apr-polygon" className="home-text162">
                6.07% APR
              </span>
            </div>
          </div>
          <div className="home-frame11712750621">
            <div className="home-frame413">
              <div className="home-frame813">
                <img
                  alt="gnosisgnognologo11110"
                  src="/external/gnosisgnognologo11110-31e5.svg"
                  className="home-gnosisgnognologo11"
                />
                <div className="home-group113">
                  <span className="home-text163">GNO</span>
                </div>
                <div className="home-group213">
                  <span className="home-text164">Gnosis</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-njyo.svg"
                className="home-line513"
              />
              <span id="apr-gnosis" className="home-text165">
                9.75% APR
              </span>
            </div>
          </div>
          <div className="home-frame-stellar1">
            <div className="home-frame414">
              <div className="home-frame814">
                <img
                  alt="stellarlogo"
                  src="/external/logos/logo-stellar-200h.png"
                  className="home-stellar1"
                />
                <div className="home-group114">
                  <span className="home-text166">XLM</span>
                </div>
                <div className="home-group214">
                  <span className="home-text167">Stellar</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-njyo.svg"
                className="home-line514"
              />
              <span id="apr-stellar" className="home-text168">
                N/A
              </span>
            </div>
          </div>
          <div className="home-frame-avail1">
            <div className="home-frame415">
              <div className="home-frame815">
                <img
                  alt="availlogo"
                  src="/external/logos/logo-avail-200h.png"
                  className="home-avail1"
                />
                <div className="home-group115">
                  <span className="home-text169">AVL</span>
                </div>
                <div className="home-group215">
                  <span className="home-text170">Avail</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-njyo.svg"
                className="home-line515"
              />
              <span id="apr-avail" className="home-text171">
                20% APR
              </span>
            </div>
          </div>
          <div className="home-frame-flare1">
            <div className="home-frame416">
              <div className="home-frame816">
                <img
                  alt="flarelogo"
                  src="/external/logos/flare-flr-logo-200h.png"
                  className="home-flare2"
                />
                <div className="home-group116">
                  <span className="home-text172">FLR</span>
                </div>
                <div className="home-group216">
                  <span className="home-text173">Flare</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-njyo.svg"
                className="home-line516"
              />
              <span id="apr-flare" className="home-text174">
                12% APR
              </span>
            </div>
          </div>
          <div className="home-frame-near1">
            <div className="home-frame417">
              <div className="home-frame817">
                <img
                  alt="nearlogo"
                  src="/external/logos/logo-near1-200h.png"
                  className="home-near1"
                />
                <div className="home-group117">
                  <span className="home-text175">NEAR</span>
                </div>
                <div className="home-group217">
                  <span className="home-text176">Near</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-njyo.svg"
                className="home-line517"
              />
              <span id="apr-near" className="home-text177">
                9.18% APR
              </span>
            </div>
          </div>
          <div className="home-frame11712750642">
            <div className="home-frame418">
              <div className="home-frame818">
                <div className="home-frame11712751152">
                  <div className="home-ethereumlogo201412">
                    <div className="home-group4">
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-zl48g.svg"
                        className="home-vector16"
                      />
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-w7fn.svg"
                        className="home-vector17"
                      />
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-vf95.svg"
                        className="home-vector18"
                      />
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-bvw4.svg"
                        className="home-vector19"
                      />
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-kot5.svg"
                        className="home-vector20"
                      />
                      <img
                        alt="Vector1110"
                        src="/external/vector1110-3w46.svg"
                        className="home-vector21"
                      />
                    </div>
                  </div>
                </div>
                <div className="home-group118">
                  <span className="home-text178">ETH</span>
                </div>
                <div className="home-group218">
                  <span className="home-text179">Ethereum</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-7rq.svg"
                className="home-line518"
              />
              <span id="apr-ethereum1" className="home-text180">
                3.09% APR
              </span>
            </div>
          </div>
          <div className="home-frame11712750632">
            <div className="home-frame419">
              <div className="home-frame819">
                <img
                  alt="Fill11110"
                  src="/external/btc-logo-200h.png"
                  className="home-fill12"
                />
                <div className="home-group119">
                  <span className="home-text181">BTC</span>
                </div>
                <div className="home-group219">
                  <span className="home-text182">Bitcoin</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-yzp.svg"
                className="home-line519"
              />
              <span id="apr-bitcoin1" className="home-text183">
                4.00% APR
              </span>
            </div>
          </div>
          <div className="home-frame122">
            <div className="home-frame420">
              <div className="home-frame820">
                <img
                  alt="Polygon1110"
                  src="/external/polygon1110-b058.svg"
                  className="home-polygon2"
                />
                <div className="home-group120">
                  <span className="home-text184">MATIC</span>
                </div>
                <div className="home-group220">
                  <span className="home-text185">Polygon</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-93o.svg"
                className="home-line520"
              />
              <span id="apr-polygon1" className="home-text186">
                6.07% APR
              </span>
            </div>
          </div>
          <div className="home-frame11712750622">
            <div className="home-frame421">
              <div className="home-frame821">
                <img
                  alt="gnosisgnognologo11110"
                  src="/external/gnosisgnognologo11110-31e5.svg"
                  className="home-gnosisgnognologo12"
                />
                <div className="home-group121">
                  <span className="home-text187">GNO</span>
                </div>
                <div className="home-group221">
                  <span className="home-text188">Gnosis</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-njyo.svg"
                className="home-line521"
              />
              <span id="apr-gnosis1" className="home-text189">
                9.75% APR
              </span>
            </div>
          </div>
          <div className="home-frame-stellar2">
            <div className="home-frame422">
              <div className="home-frame822">
                <img
                  alt="stellarlogo"
                  src="/external/logos/logo-stellar-200h.png"
                  className="home-stellar2"
                />
                <div className="home-group122">
                  <span className="home-text190">XLM</span>
                </div>
                <div className="home-group222">
                  <span className="home-text191">Stellar</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-njyo.svg"
                className="home-line522"
              />
              <span id="apr-stellar1" className="home-text192">
                N/A
              </span>
            </div>
          </div>
          <div className="home-frame-avail2">
            <div className="home-frame423">
              <div className="home-frame823">
                <img
                  alt="availlogo"
                  src="/external/logos/logo-avail-200h.png"
                  className="home-avail2"
                />
                <div className="home-group123">
                  <span className="home-text193">AVL</span>
                </div>
                <div className="home-group223">
                  <span className="home-text194">Avail</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-njyo.svg"
                className="home-line523"
              />
              <span id="apr-avail1" className="home-text195">
                20% APR
              </span>
            </div>
          </div>
          <div className="home-frame-flare2">
            <div className="home-frame424">
              <div className="home-frame824">
                <img
                  alt="flarelogo"
                  src="/external/logos/flare-flr-logo-200h.png"
                  className="home-flare3"
                />
                <div className="home-group124">
                  <span className="home-text196">FLR</span>
                </div>
                <div className="home-group224">
                  <span className="home-text197">Flare</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-njyo.svg"
                className="home-line524"
              />
              <span id="apr-flare1" className="home-text198">
                12% APR
              </span>
            </div>
          </div>
          <div className="home-frame-near2">
            <div className="home-frame425">
              <div className="home-frame825">
                <img
                  alt="nearlogo"
                  src="/external/logos/logo-near1-200h.png"
                  className="home-near2"
                />
                <div className="home-group125">
                  <span className="home-text199">NEAR</span>
                </div>
                <div className="home-group225">
                  <span className="home-text200">Near</span>
                </div>
              </div>
              <img
                alt="Line51110"
                src="/external/line51110-njyo.svg"
                className="home-line525"
              />
              <span id="apr-near1" className="home-text201">
                9.18% APR
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="home-frame1171275280">
        <span className="home-text202">FAQ</span>
        <div className="home-frame1171275279">
          <div className="home-frame1171275098">
            <span className="home-text203">Gateway staking</span>
            <img
              alt="ChevronLeft1110"
              src="/external/chevronleft1110-kcz-200h.png"
              className="home-chevron-left1"
            />
          </div>
          <div className="home-frame1171275099">
            <span className="home-text204">Slashing</span>
            <img
              alt="ChevronLeft1110"
              src="/external/chevronleft1110-9evn-200h.png"
              className="home-chevron-left2"
            />
          </div>
          <div className="home-frame1171275100">
            <span className="home-text205">Trusted partner</span>
            <img
              alt="ChevronLeft1110"
              src="/external/chevronleft1110-xbaoq-200h.png"
              className="home-chevron-left3"
            />
          </div>
        </div>
      </div>
      <StakewayFooter></StakewayFooter>
    </div>
  )
}

export default Home
